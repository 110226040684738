<template>
  <Page title="Browse Shipments">
    <v-form @submit.prevent="">
      <v-row dense>
        <v-col cols="12" :sm="hasMultipleClients ? 5 : 6">
          <v-text-field
            label="ID"
            v-model="options.params.id"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" :sm="hasMultipleClients ? 2 : 6">
          <v-checkbox
            v-model="options.params.in_transit"
            label="In Transit"
          ></v-checkbox>
        </v-col>
        <v-col v-if="hasMultipleClients" cols="12" sm="5">
          <v-select
            :items="clients"
            item-value="id"
            item-text="title_with_id"
            v-model="options.params.client_id"
            label="Client"
            clearable
          />
        </v-col>
      </v-row>
    </v-form>
    <v-data-table v-bind="defaultAttrs" :options.sync="options">
      <template v-slot:item.samples="{ item }">
        <v-avatar>
          <v-avatar
            :color="!item.samples.length ? 'error' : 'primary'"
            size="25"
          >
            <span class="white--text">{{ item.samples.length }}</span>
          </v-avatar>
        </v-avatar>
      </template>
      <template v-slot:item.received="{ item }">
        <v-icon :color="item.received ? 'success' : 'warning'">
          {{ item.received ? "mdi-check" : "mdi-progress-clock" }}
        </v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span class="mx-2">
              <v-icon
                @click.stop="() => shippingLabelModal(item)"
                v-bind="attrs"
                v-on="on"
                :color="
                  item.easypost_shipment_id.length
                    ? item.tracking_number
                      ? 'primary'
                      : 'warning'
                    : ''
                "
              >
                mdi-barcode-scan
              </v-icon>
            </span>
          </template>
          <span>Manage Shipment</span>
        </v-tooltip>
        <v-tooltip
          v-if="
            (item.easypost_shipment_id && !item.date_shipped) ||
              (item.shipment_method_id && !item.date_shipped)
          "
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <span class="mx-2">
              <v-icon
                class="action-icon"
                @click.stop="() => resetShipping(item)"
                v-bind="attrs"
                v-on="on"
                color="primary"
              >
                mdi-refresh
              </v-icon>
            </span>
          </template>
          <span>Start Shipping Over</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span class="mx-2">
              <v-icon
                @click.stop="() => shippingStatusModal(item)"
                v-bind="attrs"
                v-on="on"
                :color="
                  item.easypost_shipment_id.length && item.tracking_number
                    ? item.date_shipped
                      ? 'primary'
                      : 'warning'
                    : ''
                "
              >
                mdi-truck-delivery
              </v-icon>
            </span>
          </template>
          <span>Shipping Status</span>
        </v-tooltip>
        <v-tooltip top z-index="2" v-if="!item.is_received">
          <template v-slot:activator="{ on, attrs }">
            <router-link
              :to="{
                name: 'Edit Shipment',
                params: { shipmentId: item.id }
              }"
            >
              <v-icon class="action-icon" v-bind="attrs" v-on="on">
                mdi-pencil
              </v-icon>
            </router-link>
          </template>
          <span>Edit Shipment</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog v-model="shipping_label.active" width="600">
      <ShippingLabel
        v-if="shipping_label.item !== false"
        v-model="shipping_label.item"
        v-on:close-dialog="() => shippingLabelModal(false)"
        v-on:refresh="() => refresh()"
      />
    </v-dialog>
    <v-dialog v-model="shipping_status.active" width="600">
      <ShippingStatus
        v-if="shipping_status.item !== false"
        v-model="shipping_status.item"
        v-on:close-dialog="() => shippingStatusModal(false)"
        v-on:refresh="() => refresh()"
      />
    </v-dialog>
  </Page>
</template>

<script>
import Page from "@/components/layout/Page";
import ShippingLabel from "@/components/shipments/Label/ShippingLabel";
import ShippingStatus from "@/components/shipments/ShippingStatus/ShippingStatus";
import dataTablePagination from "@/mixins/dataTablePagination";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "ShipmentsBrowse",
  components: { Page, ShippingLabel, ShippingStatus },
  mixins: [dataTablePagination],
  data() {
    return {
      shipping_label: {
        active: false,
        item: false
      },
      shipping_status: {
        active: false,
        item: false
      },
      headers: [
        { text: "ID", value: "id" },
        { text: "Tracking Number", value: "tracking_number" },
        { text: "Samples", value: "samples" },
        { text: "Received at Lab", value: "received" },
        { text: "Date Shipped", value: "date_shipped" },
        { text: "Status", value: "common_status" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      options: {
        params: {
          id: "",
          in_transit: this.$route.query.in_transit,
          client_id: ""
        }
      }
    };
  },
  computed: {
    ...mapGetters("clients", ["hasMultipleClients"]),
    ...mapState("clients", { clients: "items" })
  },
  methods: {
    ...mapActions("shipments", [
      "getPaginated",
      "editShipment",
      "epUpdateShipment",
      "editShipment"
    ]),
    ...mapActions(["notify"]),
    ...mapActions("baseData", ["getShippingMethods"]),
    shippingLabelModal(item = false) {
      this.shipping_label.active = item !== false;
      this.shipping_label.item = item;
    },
    shippingStatusModal(item = false) {
      this.shipping_status.active = item !== false;
      this.shipping_status.item = item;
    },
    resetShipping(item = false) {
      let promiseChain = [];

      // If online ship
      if (item.easypost_shipment_id !== "") {
        promiseChain = promiseChain.push(
          this.epUpdateShipment({
            shipment: item,
            data: {
              refund: true
            }
          }).then(() => {
            this.refresh();
          })
        );
      }
      // If self ship
      if (item.shipment_method_id != null && item.easypost_shipment_id === "") {
        item.shipment_method_id = null;
        item.shipment_method_other = null;
        item.shipment_method_type = null;
        item.tracking_number = null;
        item.shipping_cost = null;
        promiseChain = promiseChain.push(
          this.editShipment(item).then(() => {
            this.refresh();
          })
        );
      }
      if (promiseChain.length >= 1) {
        Promise.all(promiseChain);
      }
    }
  },
  beforeMount() {
    if (this.$route.query && Object.keys(this.$route.query).length > 0) {
      this.options.params = Object.assign({}, this.$route.query);
      this.$router.replace({ name: this.$route.name }); // remove query string
    }
  },
  mounted() {
    Promise.all([this.getShippingMethods()]).then(() => (this.loading = false));
  }
};
</script>

<style scoped></style>
